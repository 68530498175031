import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const BlogPostTemplate = ({
  contentComponent,
  description,
  title,
  helmet,
  main,
  image,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <section className="about-header project-post">
        <div className="about-first ">
          <div className="about-header-title">
            <h1 data-aos="fade-right" data-aos-delay="600" data-aos-duration="2000">{title}
            </h1>
          </div>
          <div data-aos="fade-left" data-aos-delay="400" data-aos-duration="2000" className="main-image">
            <PreviewCompatibleImage className="image-header" imageInfo={image} alt="main"/>
            <div className="about-text">
              <p data-aos="fade-left" data-aos-delay="800" data-aos-duration="2000">{description}</p>
            </div>
          </div>
        </div>
      </section>
      <section data-aos="fade" data-aos-delay="200" data-aos-duration="2000"  className="project-images-second">
        <PreviewCompatibleImage className="image-header" imageInfo={main.image1} alt="main"/>
        <PreviewCompatibleImage className="image-header" imageInfo={main.image2} alt="main"/>
        <PreviewCompatibleImage className="image-header" imageInfo={main.image3} alt="main"/>
      </section>
      <section className="projects-link">
        <Link to="/blog" data-aos="fade" data-aos-delay="200" data-aos-duration="2000"className="main-button">
          Back to projects
        </Link>
      </section>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
  main: PropTypes.shape({
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        image={post.frontmatter.image}
        main={post.frontmatter.main}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        main {
          image1 {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image2 {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image3 {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
